<template>
  <v-dialog v-model="show" persistent width="360">
    <v-card>
      <v-card-title
        >Bạn muốn xóa nhóm khách hàng?<br />
        Bạn chắc chứ?</v-card-title
      >
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="show = false">Không</v-btn>
        <v-btn :loading="loading" color="primary" @click="submitDelete"
          >Có</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { deleteCustomerTypes } from "@/api/loaikhachhang";
export default {
  data: () => {
    return {
      show: false,
      id: null,
      loading: false,
    };
  },
  methods: {
    open(item) {
      this.show = true;
      this.id = item.id;
    },
    async submitDelete() {
      try {
        this.loading = true;
        await deleteCustomerTypes({ id: this.id });
        this.show = false;
        this.loading = false;
        this.$emit("on-done");
        this.$toast.info("Xóa nhóm khách hàng thành công", {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
        });
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.show = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
