<template>
  <PageListLayout
    icon="mdi-apps"
    title="DANH MỤC DỮ LIỆU"
    subTitle="Danh sách các danh mục dữ liệu trên hệ thống"
    noFilter
  >
    <template slot="side-right">
      <!-- <v-switch
        height="30px"
        style="width: 190px"
        class="mt-0 switch--style"
        v-model="singleExpand"
        label="Mở 1 danh mục"
        hide-details
      ></v-switch> -->
    </template>
    <v-layout class="d-flex justify-center pt-8 pb-8" v-if="loading">
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-layout>
    <v-data-iterator
      :items="danhMucs"
      item-key="name"
      :single-expand="singleExpand"
      hide-default-footer
      v-else
    >
      <template v-slot:default="{ items, isExpanded, expand }">
        <v-row>
          <v-col cols="12" sm="6" md="4" lg="4">
            <v-card outlined>
              <v-card-title>
                <h5>Loại hợp đồng</h5>
              </v-card-title>
              <v-layout align-center class="pb-2">
                <v-switch
                  hide-details
                  v-model="switchLoaiHopDong"
                  :label="switchLoaiHopDong ? 'Đã mở' : 'Đã đóng'"
                  class="pl-4 mt-0"
                ></v-switch>
              </v-layout>

              <v-list v-if="switchLoaiHopDong" dense>
                <v-text-field
                  style="width: 90%; padding-left: 80px; margin-bottom: 20px"
                  append-icon="mdi-magnify"
                  v-model="search"
                  label="Tìm kiếm"
                  clearable
                  single-line
                  hide-details
                  class="pt-0 mt-0"
                ></v-text-field>

                <v-list class="list_style" subheader two-line>
                  <v-list-item v-for="item in loaiHopDong" :key="item.id">
                    <v-list-item-avatar>
                      <v-icon class="grey lighten-1" dark>
                        mdi-book-open-variant
                      </v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title v-text="item.name"></v-list-item-title>

                      <v-list-item-subtitle>{{
                        item.description ? "Mô tả: " + item.description : ""
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn icon @click="editDialog(item, 'loaihopdong')">
                        <v-icon color="grey lighten-1">mdi-pencil</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-list>
            </v-card>
          </v-col>
          <v-col
            v-for="item in items"
            :key="item.id"
            cols="12"
            sm="6"
            md="4"
            lg="4"
          >
            <v-card outlined>
              <v-card-title>
                <h5>{{ item.name }}</h5>
              </v-card-title>
              <v-layout align-center class="pb-2">
                <v-switch
                  hide-details
                  :input-value="isExpanded(item)"
                  :label="isExpanded(item) ? 'Đã mở' : 'Đã đóng'"
                  class="pl-4 mt-0"
                  @change="(v) => expand(item, v)"
                ></v-switch>
                <v-spacer></v-spacer>
                <v-btn
                  class="mx-2"
                  icon
                  dark
                  color="var(--primary-color)"
                  small
                  @click="addDanhMucCon(item)"
                >
                  <v-icon dark> mdi-plus </v-icon>
                </v-btn>
              </v-layout>

              <v-list v-if="isExpanded(item)" dense>
                <v-text-field
                  style="width: 90%; padding-left: 80px; margin-bottom: 20px"
                  append-icon="mdi-magnify"
                  v-model="search"
                  label="Tìm kiếm"
                  clearable
                  single-line
                  hide-details
                  class="pt-0 mt-0"
                ></v-text-field>
                <v-list class="list_style" subheader two-line>
                  <v-list-item
                    v-for="danhMucCon in item.children"
                    :key="danhMucCon.id"
                  >
                    <v-list-item-avatar>
                      <v-icon class="grey lighten-1" dark>
                        mdi-book-open-variant
                      </v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title
                        v-text="danhMucCon.name"
                      ></v-list-item-title>

                      <v-list-item-subtitle>{{
                        danhMucCon.mo_ta ? "Mô tả: " + danhMucCon.mo_ta : ""
                      }}</v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-chip
                        color="success"
                        dark
                        small
                        v-if="danhMucCon.active"
                      >
                        Đang hoạt động
                      </v-chip>
                      <v-chip color="pink" dark small v-else>
                        Không hoạt động
                      </v-chip>
                    </v-list-item-action>
                    <v-list-item-action>
                      <v-btn
                        icon
                        @click="editDanhMucCon(danhMucCon, item.code)"
                      >
                        <v-icon color="grey lighten-1">mdi-pencil</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-list>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="4">
            <v-card outlined>
              <v-card-title>
                <h5>Nhóm khách hàng</h5>
              </v-card-title>
              <v-layout align-center class="pb-2">
                <v-switch
                  hide-details
                  v-model="switchKH"
                  :label="switchKH ? 'Đã mở' : 'Đã đóng'"
                  class="pl-4 mt-0"
                ></v-switch>
                <v-spacer></v-spacer>
                <v-btn
                  class="mx-2"
                  icon
                  dark
                  color="var(--primary-color)"
                  small
                  @click="addDialog('nhomkhachhang')"
                >
                  <v-icon dark> mdi-plus </v-icon>
                </v-btn>
              </v-layout>

              <v-list v-if="switchKH" dense>
                <v-text-field
                  style="width: 90%; padding-left: 80px; margin-bottom: 20px"
                  append-icon="mdi-magnify"
                  v-model="search"
                  label="Tìm kiếm"
                  clearable
                  single-line
                  hide-details
                  class="pt-0 mt-0"
                ></v-text-field>

                <v-list class="list_style" subheader two-line>
                  <v-list-item v-for="item in nhomKhachHang" :key="item.id">
                    <v-list-item-avatar>
                      <v-icon class="grey lighten-1" dark>
                        mdi-book-open-variant
                      </v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title v-text="item.name"></v-list-item-title>

                      <v-list-item-subtitle>{{
                        item.description ? "Mô tả: " + item.description : ""
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <div>
                        <v-btn icon @click="editDialog(item, 'nhomkhachhang')">
                          <v-icon color="grey lighten-1">mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn
                          icon
                          @click="onDeleteCustomerType(item)"
                          color="grey lighten-1"
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </div>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-list>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="4">
            <v-card outlined>
              <v-card-title>
                <h5>Danh Sách ngân hàng</h5>
              </v-card-title>
              <v-layout align-center class="pb-2">
                <v-switch
                  hide-details
                  v-model="switchNganHang"
                  :label="switchNganHang ? 'Đã mở' : 'Đã đóng'"
                  class="pl-4 mt-0"
                ></v-switch>
                <v-spacer></v-spacer>
                <v-btn
                  class="mx-2"
                  icon
                  dark
                  color="var(--primary-color)"
                  small
                  @click="addDialog('Danhsachnganhang')"
                >
                  <v-icon dark> mdi-plus </v-icon>
                </v-btn>
              </v-layout>

              <v-list v-if="switchNganHang" dense>
                <v-text-field
                  style="width: 90%; padding-left: 80px; margin-bottom: 20px"
                  append-icon="mdi-magnify"
                  v-model="searchNganHang"
                  label="Tìm kiếm"
                  clearable
                  single-line
                  hide-details
                  class="pt-0 mt-0"
                ></v-text-field>

                <v-list class="list_style" subheader two-line>
                  <v-list-item v-for="item in danhSachNganHang" :key="item.id">
                    <v-list-item-avatar>
                      <v-icon class="grey lighten-1" dark>
                        mdi-book-open-variant
                      </v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title
                        v-text="item.vn_name"
                      ></v-list-item-title>

                      <v-list-item-subtitle>{{
                        item.shortName ? "Tên viết tắt: " + item.shortName : ""
                      }}</v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-chip color="success" dark small v-if="item.active">
                        Đang hoạt động
                      </v-chip>
                      <v-chip color="pink" dark small v-else>
                        Không hoạt động
                      </v-chip>
                    </v-list-item-action>
                    <v-list-item-action>
                      <v-btn icon @click="editDialog(item, 'Danhsachnganhang')">
                        <v-icon color="grey lighten-1">mdi-pencil</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
    <create-edit ref="danhmuc" @on-done="onDone"></create-edit>
    <CreateEditNganHang
      ref="nganhang"
      @on-done="fetchDanhSachNganHang"
    ></CreateEditNganHang>
    <CreateEditKH ref="khachhang" @on-done="fetchNhomKhachHang"></CreateEditKH>
    <CreateEditLoaiHopDong ref="loaihopdong" @on-done="fetchLoaiHopDong" />
    <ConfirmDeleteCustomerType
      @on-done="fetchNhomKhachHang"
      ref="delete_customer_type"
    ></ConfirmDeleteCustomerType>
  </PageListLayout>
</template>

<script>
import { debounce } from "lodash";
import { getDanhMuc } from "@/api/danhmuc";
import CreateEdit from "./create-edit";
import ConfirmDeleteCustomerType from "./confirm-delete-customer-type.vue";
import CreateEditNganHang from "./create-edit-nganhang";
import CreateEditKH from "./create-edit-nhomkh";
import CreateEditLoaiHopDong from "./create-edit-loaihopdong";
import { getAllCustomerTypes, deleteCustomerTypes } from "@/api/loaikhachhang";
import { index as getDanhSachNganHang } from "@/api/nganhang.api";
import { getAllContractTypes } from "@/api/loaihopdong";
export default {
  components: {
    CreateEdit,
    CreateEditNganHang,
    CreateEditKH,
    CreateEditLoaiHopDong,
    ConfirmDeleteCustomerType,
  },
  data: () => ({
    singleExpand: false,
    search: null,
    loading: false,
    danhMucs: [],
    nhomKhachHang: [],
    danhSachNganHang: [],
    loaiHopDong: [],
    switchNganHang: false,
    switchKH: false,
    switchLoaiHopDong: false,
    searchNganHang: null,
  }),
  mounted() {
    this.loading = true;
    Promise.all([
      this.fetchLoaiHopDong(),
      this.getData(),
      this.fetchDanhSachNganHang(),
      this.fetchNhomKhachHang(),
      this.fetchKhuVuc(),
    ]).finally(() => {
      this.loading = false;
    });
  },
  watch: {
    searchNganHang: debounce(async function(val) {
      this.fetchDanhSachNganHang();
    }, 300),
  },
  methods: {
    async getData() {
      let list_code = ["LOAIBAOGIA", "DANHSACHKHO"];
      let res = await getDanhMuc({ list_code });
      this.danhMucs = res;
    },
    async fetchLoaiHopDong() {
      const res = await getAllContractTypes();
      this.loaiHopDong = res;
    },
    async fetchNhomKhachHang() {
      const res = await getAllCustomerTypes();
      this.nhomKhachHang = res;
    },
    async fetchKhuVuc() {
      const res = await getAllCustomerTypes();
      this.khuvuc = res;
    },
    async fetchDanhSachNganHang() {
      const res = await getDanhSachNganHang({
        pagination: false,
        search: this.searchNganHang,
      });
      this.danhSachNganHang = res;
    },
    onDone(params) {
      let { data, code, isCreate } = params;
      for (let i = 0; i < this.danhMucs.length - 1; i++) {
        let element = this.danhMucs[i];
        if (element.code == code) {
          if (isCreate) {
            this.danhMucs[i].children.push(data);
          } else {
            let index = this.danhMucs[i].children.findIndex(
              (x) => x.id == data.id
            );
            if (index != -1) {
              let new_data = { ...this.danhMucs[i].children[index], ...data };
              this.$set(this.danhMucs[i].children, index, new_data);
            }
          }
        }
      }
    },
    addDanhMucCon(data) {
      this.$refs.danhmuc.showFormAdd(data);
    },
    editDanhMucCon(data, parent_code) {
      this.$refs.danhmuc.showFormEdit({ ...data, parent_code });
    },
    addDialog(type) {
      if (type == "Danhsachnganhang") {
        this.$refs.nganhang.showFormAdd();
      } else if (type == "nhomkhachhang") {
        this.$refs.khachhang.showFormAdd();
      } else if (type == "loaihopdong") {
        this.$refs.loaihopdong.showFormAdd();
      }
    },
    editDialog(item, type) {
      if (type == "Danhsachnganhang") {
        this.$refs.nganhang.showFormEdit(item);
      } else if (type == "nhomkhachhang") {
        this.$refs.khachhang.showFormEdit(item);
      } else if (type == "loaihopdong") {
        this.$refs.loaihopdong.showFormEdit(item);
      }
    },
    onDeleteCustomerType(item) {
      this.$refs.delete_customer_type.open(item);
    },
  },
};
</script>

<style scoped>
/deep/ .v-card {
  box-shadow: none !important;
}
.switch--style /deep/.v-input__slot {
  margin-bottom: 0;
}
.list_style {
  max-height: 300px;
  overflow: auto;
}
</style>
